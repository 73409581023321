define("discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "templates.insert_template"}}
    class="d-templates d-templates-modal"
  >
    <:body>
      <DTemplates::FilterableList
        @textarea={{@model.textarea}}
        @onInsertTemplate={{@model.onInsertTemplate}}
        @onAfterInsertTemplate={{@closeModal}}
      />
    </:body>
  </DModal>
  */
  {
    "id": "StqqkQ0S",
    "block": "[[[8,[39,0],[[24,0,\"d-templates d-templates-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"templates.insert_template\"],null]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@textarea\",\"@onInsertTemplate\",\"@onAfterInsertTemplate\"],[[30,2,[\"textarea\"]],[30,2,[\"onInsertTemplate\"]],[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"d-templates/filterable-list\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form.hbs",
    "isStrictMode": false
  });
  let Form = _exports.default = (_class = class Form extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "appEvents", _descriptor, this);
      if (this.args.closeModal) {
        this.appEvents.on("page:changed", this, this.args.closeModal);
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this.args.closeModal) {
        this.appEvents.off("page:changed", this, this.args.closeModal);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Form);
});